import React from 'react'
import './MotionCapture.scss'
import loadable from '@loadable/component'
import ImageSlider from '../shared/ImageSlider'
import Marked from 'react-markdown'
const Fade = loadable(() => import('../transition/Fade'))

const MotionCapture = (props) => {
  return (
    <div className="performance-container d-lg-flex justify-content-between" id="common-pl">
      <div className="title-container first-flex-column">
        <p className="sub-title">MORE ABOUT</p>
        <Fade timeout={500}>
          <h2>{props.data.title}</h2>
        </Fade>
        <Fade timeout={1000}>
          <p className="title-dec">
            <Marked parserOptions={{ commonmark: true}} source={props.data.description} />
          </p>
        </Fade>
      </div>
      <div className="second-container second-flex-column">
        <Fade timeout={1000}>
          <ImageSlider gallery={props.data.sliderImages} />
        </Fade>
      </div>
      <div className="bg-text-container d-none d-lg-block">
        <img
          src="https://res.cloudinary.com/dhuii7xg2/image/upload/c_scale,f_auto,q_auto,w_auto/v1614325549/PERFOR_cdvk7j.png"
          alt="Performance"
          className="img-fluid"
        />
      </div>
    </div>
  )
}

export default MotionCapture
